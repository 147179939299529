.whatsapp-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
}

.whatsapp-float img {
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 50%;
    background-color: #25d366;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.whatsapp-float:hover img {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}