.PhoneInputInput{
    margin: 0px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 0px;
    padding-left: 5px;
    border: none;
}

.PhoneInput{
    border: 1px solid #d9dee3;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.4375rem 0.875rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.53;
}

.PhoneInput:focus-within {
    border: 1px solid #8FE1E6;
    border-color: #8FE1E6;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.4375rem 0.875rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.53;
}

.PhoneInputInput:focus {
    outline: none;
    box-shadow: none;
    padding-left: 5px;
}