.floating-button {
    position: fixed;
    bottom: 27%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #6fd3d8;
    padding: 5px 5px;
    border-radius: 25px;
    border: none;
    z-index: 1000;
    box-shadow: 0 0px 0px rgba(30, 29, 29, 0.1);
    text-align: center;
    animation: pulse 2s infinite;
}

/* Pulse Animation */
@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    50% {
        transform: scale(1.10);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
}