.textarea-faded {
    background-color: #ECEEF1;
    /* Light gray background */
    border-color: #ECEEF1;
    /* Lighter border */
    color: #ECEEF1;
    /* Faded text color */
    cursor: not-allowed;
    /* Show not-allowed cursor */
}

.div-faded{
    background-color: #ECEEF1;
    /* Light gray background */
    border-color: #ECEEF1;
    /* Lighter border */
    color: #ECEEF1;
    /* Faded text color */
    cursor: not-allowed;
    /* Show not-allowed cursor */
}