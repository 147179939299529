pre {
    background-color: #2d2d2d;
    color: #ffffff;
    padding: 16px;
    border-radius: 8px;
    font-family: 'Courier New', Courier, monospace;
    white-space: pre-wrap;
    /* Ensures long lines break */
    word-wrap: break-word;
    font-size: 14px;
    line-height: 1.5;
    overflow-x: auto;
}

pre code {
    color: #f8f8f2;
}

pre .keyword {
    color: #c5a5c5;
    font-weight: bold;
}

pre .string {
    color: #8dc891;
}

pre .comment {
    color: #75715e;
    font-style: italic;
}

pre .number {
    color: #c78f0a;
}

.inline-code {
    background-color: #f1f1f1;
    /* Light background color */
    border: 1px solid #ddd;
    /* Border for visual separation */
    border-radius: 3px;
    /* Rounded corners */
    padding: 2px 4px;
    /* Padding for better visibility */
    font-family: monospace;
    /* Monospaced font for code */
    color: #d14;
    /* Text color for code */
}