.typing-indicator {
  display: flex;               /* Use flexbox for centering */
  justify-content: center;     /* Center horizontally */
  align-items: center;         /* Center vertically */
  height: 0px;                /* Set a fixed height for the container */
  margin-top: 10px;            /* Add some space above the indicator */
}

.typing-indicator img {
  width: 100px;                 /* Set the width of the image */
  height: auto;
  margin-left: -30px;
  animation: pulse 1.5s infinite; /* Add pulse animation */
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;              /* Fade out to 50% opacity */
  }
  100% {
    opacity: 1;                /* Fade back to full opacity */
  }
}
