
/* Loader container background */
.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(250, 246, 246, 0.5);
    /* Adjust opacity as needed */
    z-index: 9999;
    /* Ensure it overlays other content */
}

/* Spinner Container Styles */
/* Spinner Container Styles */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Adjust based on your layout */
}

/* SVG Spinner Styles */
.spinner-svg {
    width: 30px;
    /* Adjust size as needed */
    height: 30px;
    animation: spin 1.0s linear infinite;
    fill: #3498db;
    /* Color of the spinner */
    transform: scale(0.6);
    /* Scale down the SVG paths */
}

/* Spinner Animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}