a{color:#1fc2cc!important;}
.bg-primary{background-color:#1fc2cc!important}
.btn-primary{background-color:#1fc2cc!important;border-color:#1fc2cc!important;}
.form-control:focus{border-color:#1fc2cc!important}
.bg-menu-theme .menu-inner > .menu-item.active:before{background:#1fc2cc}
.bg-menu-theme .menu-inner > .menu-item.active > .menu-link{color:#172638;background-color:#e2f8fb!important;}
.app-brand-text.demo{text-transform:capitalize;color:#172638}
.sec-chat-box{height:450px;overflow-x: hidden;overflow-y: auto;}
.sec-chat-box .chat-user{float:right;width:75%;margin-bottom:10px}
.sec-chat-box .chat-user .chat-bubble-1{background-color:#e2f8fb;padding: 10px;border-radius: 10px;color:#172638}
.sec-chat-box .chat-user .chat-bubble-1 p{margin-bottom:0}
.sec-chat-box .chat-reply{float:left;width:100%;margin-bottom:10px;display:flex}
.sec-chat-box .chat-reply .chat-icon{display:flex;margin-right:10px}
.sec-chat-box .chat-reply .chat-icon img{width:30px;height:30px}
.sec-chat-box .chat-reply .chat-bubble-0{background-color: #f6feff;padding: 10px;
    border-radius: 10px;color:#172638;display:flex}
.sec-chat-box .chat-reply .chat-bubble-0 p{margin-bottom:0}
.sec-chat-input{padding:15px 10px}
.sec-chat-input .input-chat{height:30px;min-height:auto;}
.sec-chat-input .input-chat::-webkit-scrollbar {
    display: none;
}
.sec-chat-input .btn-abs-chat{position:absolute;top:10px;right:10px;}